<template>
    <form-layout
        ref="dataForm"
        :valid.sync="valid"
        :mode="mode"
        :form-title="schema.titleSingular"
        :hide-title="hideTitle"
        :hide-delete="hideDelete"
        :hide-cancel="hideCancel"
        :is-edit="isEdit"
        :is-saving="isSaving"
        :is-readonly="isReadonly"
        @save="save"
        @cancel="cancel()"
        @deleteItem="deleteItem()">
        <template v-slot:content="">
            <template v-for="field in filteredFields">
                <component
                    :is="field.component"
                    v-if="!isFiltered(field.camelSingular)"
                    :key="field.value"
                    v-model="item[field.camelSingular]"
                    :field="field"
                    :filter="filter"
                    :is-edit="isEdit"
                    :is-readonly="isReadonly"
                    :is-dense="isDense"
                    :is-disabled="isDisabled"
                    :is-saving="isSaving"
                    :schema="schema"
                    :item="item"
                    @save="save" />
            </template>
        </template>
    </form-layout>
</template>

<script>
import formMixin from "@/mixins/formMixin";

export default {
    mixins: [formMixin]
};
</script>
