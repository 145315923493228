import axios from "axios";

let formMixin = {
    props: {
        value: {
            type: Object,
            default: null,
        },
        url: {
            //optional, will use default in schema
            type: String,
            default: null,
        },
        schema: {
            type: Object,
            default: null,
        },
        filter: {
            type: Object,
            default: null,
        },
        hideDelete: {
            type: Boolean,
            default: false,
        },
        hideOffset: {
            type: Boolean,
            default: false,
        },
        isReadonly: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        isDense: {
            type: Boolean,
            default: false,
        },
        hideTitle: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        formLayout: () => import("@/components/FormLayout.vue"),
    },

    data() {
        return {
            valid: true,
            isSaving: false,
            item: {},
            hideCancel: false,
        };
    },

    methods: {
        async deleteItem() {
            if (!this.isEdit || !confirm("Are you sure you want to delete this item?")) {
                return;
            }

            try {
                await axios.delete(this.getUrl + this.item.id);
                this.$emit("delete", this.item);
            } catch (error) {
                this.handleError(error);
            }
        },

        async save(persistForm) {
            this.hideCancel = false;
            if (this.isSaving) {
                return;
            }
            if (!this.$refs.dataForm.$refs.editForm.validate()) {
                return;
            }
            this.isSaving = true;
            let item = Object.assign({}, this.item);
            try {
                let isEdit = this.isEdit;
                let response = await (isEdit
                    ? axios.put(this.getUrl, item)
                    : axios.post(this.getUrl, item));

                this.item = response.data;
                if (!persistForm) {
                    this.$emit("input", this.item);
                    this.$emit(isEdit ? "update" : "add", this.item);
                } else {
                    this.hideCancel = true;
                }
            } catch (error) {
                this.$emit("open");
                this.handleError(error);
            } finally {
                this.isSaving = false;
            }
        },

        cancel() {
            this.$emit("close");
            this.$emit("cancel");
        },

        isFiltered(property) {
            if (this.isEmpty(this.filter)) {
                return false;
            }

            return Object.prototype.hasOwnProperty.call(this.filter, property);
        },
    },

    watch: {
        value: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.item = JSON.parse(JSON.stringify(value));
                } else {
                    this.item = {};
                }

                if (!this.isEmpty(this.filter)) {
                    Object.assign(this.item, this.filter);
                }
            },
        },
    },

    computed: {
        isEdit() {
            return !this.isEmpty(this.item) && this.item.id != null;
        },
        mode() {
            return this.isEdit ? "Edit " : "Add ";
        },
        canSave() {
            //TODO: check if this is needed
            if (this.isEmpty(this.item)) {
                return false;
            }
            return this.schema.fields.filter(f => f.isRequired).every(f => this.item[f.value]);
        },
        getUrl() {
            if (!this.isEmpty(this.url)) {
                return this.url;
            }
            return "/api/" + this.schema.pascalPlural + "/";
        },
        filteredFields() {
            if (this.isEmpty(this.filter)) {
                return this.schema.fields;
            }

            return this.schema.fields.filter(
                h => !Object.prototype.hasOwnProperty.call(this.filter, h.camelSingular)
            );
        }
    },
};

export default formMixin;
